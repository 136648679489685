"use client";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";

import clsx from "clsx";
import { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";
import type { SwiperOptions } from "swiper/types";

import PC from "@/components/atoms/ProductCard";
import type { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";

import styles from "./product-slider.module.css";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "swiper-container": any;
      "swiper-slide": any;
    }
  }
}

const Slider = ({
  cards,
  cardsPerRow,
}: {
  cards: {
    card: ProductCard;
    tag: string;
  }[];
  cardsPerRow?: number;
}) => {
  register();
  const swiperRef = useRef<{
    initialize: () => void;
  }>(null);

  useEffect(() => {
    const swiperContainer = swiperRef.current;
    if (!swiperContainer) return;
    const params: SwiperOptions = {
      navigation: true,
      initialSlide: 0,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },

      breakpoints: {
        0: {
          slidesPerView: 1.1,
          spaceBetween: 6,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
      },
      injectStyles: [
        `
      .swiper-horizontal{
        position: unset;
      }
      .swiper-button-next {
        position: absolute;
        inset: auto;
        top: 50%;
        color: black;
        right: 3vw;
      }
      .swiper-button-prev {
        position: absolute;
        inset: auto;
        top: 50%;
        color: black;
        left: 3vw;
      }
      .swiper-button-next:hover, .swiper-button-prev:hover{
        color: var(--color-hover);
      }
      .swiper-button-next:after{
        content: '>';
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 400;
      }
      .swiper-button-prev:after{
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 400;
        content: '<';
      }
      .swiper-pagination {
        display:none;
      }
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, [swiperRef]);

  return (
    <div className={clsx(styles.root, "product-slider")}>
      <swiper-container init="false" ref={swiperRef}>
        {cards.map((card, i) => (
          <swiper-slide key={`${card.card?.core?.slug}-${i}`}>
            <PC key={i} product={card.card} cardsPerRow={cardsPerRow} />
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
};

const ProductSlider = ({
  cards,
  cardsPerRow,
}: {
  cards: {
    card: ProductCard;
    tag: string;
  }[];
  cardsPerRow?: number;
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={rootRef} className={styles["slider-wrapper"]}>
      <Slider cards={cards} />

      <div className={clsx(styles.root, styles.column__root, "column-root")}>
        {cards?.map((card, i) => (
          <PC key={i} product={card.card} cardsPerRow={cardsPerRow} />
        ))}
      </div>
    </div>
  );
};

export default ProductSlider;
