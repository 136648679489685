import { Breadcrumbs, Heading } from "@/components/atoms";
import Preamble from "@/components/atoms/Preamble/Preamble";
import type {
  ArticleCategoryStoryblok,
  ArticleStoryblok
} from "@/types/storyblok-blok-types";

import formatArticleCard from "@/lib/utils/formatArticleCards";
import StoryblokComponent from "../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";
import ArticleCard from "../ArticleCard/ArticleCard";
import StoryblokLink from "../StoryblokLink";
import Navigation from "./ArticleCategoryNavigation/NavigationServer";
import styles from "./article-category.module.css";

export type ArticleCardProps = {
  content: ArticleStoryblok;
  name: string;
  full_slug: string;
};

const ArticleCategory = ({
  blok,
  articles
}: {
  blok: ArticleCategoryStoryblok;
  articles?: ArticleCardProps[];
}) => {
  const heroImage = blok?.backgroundMedia?.[0];
  const heroPreamble = blok?.preamble;
  const heroTitle = blok?.title;
  const heroButton = blok?.button?.[0];
  const subtitleBlok = blok?.Subtitle?.[0];
  const category = "Articles";
  const body = blok?.body;

  return (
    <main>
      <section className={styles.hero}>
        {heroImage && (
          <div
            id="media-positioner"
            className={styles.image}
            data-blok
            data-blok-background
            data-layout={"full"}
          >
            <StoryblokComponent blok={heroImage} />
          </div>
        )}
        {!!heroPreamble && <Preamble>{heroPreamble}</Preamble>}
        {heroTitle && (
          <Heading as="h1" styledAs="h2" className={styles.hero__title}>
            {heroTitle}
          </Heading>
        )}
        {!!heroButton && (
          <StoryblokLink
            blok={{
              link: heroButton.link,
              text: heroButton.text
            }}
          />
        )}
      </section>

      {/* sub-hero */}
      <section className={styles.subtitle__section}>
        <StoryblokComponent blok={subtitleBlok} />
      </section>

      {/* journal-showcase */}

      <div className={styles.articles__topbar}>
        <Breadcrumbs
          asDiv
          small
          breadcrumbs={[
            {
              name: "Journal",
              uri: "/article"
            },
            {
              name: category,
              uri: "/article/" + category
            }
          ]}
        />
        <Navigation />
        <div></div>
      </div>

      {body?.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}

      <div className={styles.articles__grid} data-blok>
        {articles?.map((article) => (
          <ArticleCard
            {...formatArticleCard(article)}
            key={article.full_slug}
          />
        ))}
      </div>
    </main>
  );
};

export default ArticleCategory;
