import { Content } from "@radix-ui/react-accordion";
import clsx from "clsx";
import React from "react";

import styles from "./accordion-content.module.css";

const AccordionContent = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <Content className={clsx(styles.root, className)} {...props}>
    <div className={styles.content}>{children}</div>
  </Content>
);

export default AccordionContent;
