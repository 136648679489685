"use client";

import Link from "next/link";

import { Page } from "@/components/atoms";
import getLink from "@/lib/storyblok/utils/getLink";

import { ArticlesShowcaseStoryblok } from "@/types/storyblok-blok-types";
import StoryblokComponent from "../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";
import styles from "./articles-showcase.module.css";

const ArticlesShowcase = ({
  blok,
  preview
}: {
  blok: ArticlesShowcaseStoryblok;
  preview: boolean;
}) => {
  return (
    <Page as="section" data-blok>
      <div className={styles.grid}>
        {blok?.articles?.map((item) => (
          <Link
            href={getLink(item?.link?.[0]?.link)}
            className={styles.item}
            key={item._uid}
          >
            <div className={styles.gallery}>
              <StoryblokComponent blok={item?.media?.[0]} preview={preview} />
            </div>
            <p className={styles.title}>
              <span>{item.Title}</span>
              <span style={{ paddingLeft: "0.5rem" }}>{">"}</span>
            </p>
          </Link>
        ))}
      </div>
    </Page>
  );
};

export default ArticlesShowcase;
