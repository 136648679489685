import BlokWrap from "@/components/atoms/BlokWrap";
import PC from "@/components/atoms/ProductCard";
import SkeletonPC from "@/components/atoms/ProductCard/SkeletonPC";
import type { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import type { ProductListingStoryblok } from "@/types/storyblok-blok-types";
import type { SbBlokData } from "@storyblok/js";
import { storyblokEditable } from "@storyblok/js";
import clsx from "clsx";
import { Suspense } from "react";

import styles from "./product-listing.module.css";
import ProductSliderNoSSR from "./Slider/ProductSliderNoSSR";
export const ServerUI = ({
  productCards,
  cardsPerRow
}: {
  productCards?: { card: ProductCard; tag: string }[];
  cardsPerRow?: number;
}) => (
  <div
    className={clsx(styles.root, styles.server__root)}
    data-single-item={productCards?.length === 1}
  >
    {productCards ? (
      productCards?.map((card, i) => (
        <PC
          product={card.card}
          key={card.card?.id + i.toString()}
          cardsPerRow={cardsPerRow}
        />
      ))
    ) : (
      <>
        <SkeletonPC />
        <SkeletonPC />
        <SkeletonPC />
      </>
    )}
  </div>
);
const ProductListingUI = ({
  blok,
  productCards,
  sidebyside
}: {
  blok: ProductListingStoryblok;
  sidebyside?: boolean;
  productCards?: { card: ProductCard; tag: string }[];
}) => {
  // productCards = productCards.filter(
  //   (card) => !!card?.card?.core?.name && !!card?.card?.core?.slug
  // );
  // if (!productCards.length) return null;
  const cardsPerRow = +blok?.productsPerRow || 1;
  const perRow = sidebyside && blok?.productsPerRow ? cardsPerRow : undefined;

  return (
    <>
      {productCards && productCards.length > 3 && !sidebyside ? (
        <div className={styles.slider__on__desktop} data-blok>
          <BlokWrap
            {...storyblokEditable(blok as unknown as SbBlokData)}
            animated="custom"
            className={clsx(styles.container, "product-listing")}
          >
            <Suspense fallback={<ServerUI productCards={productCards} />}>
              <ProductSliderNoSSR cards={productCards} cardsPerRow={perRow} />
            </Suspense>
          </BlokWrap>
        </div>
      ) : (
        <BlokWrap
          {...storyblokEditable(blok as unknown as SbBlokData)}
          animated="custom"
          className={clsx(styles.container, "product-listing")}
        >
          <ServerUI productCards={productCards} cardsPerRow={perRow} />
        </BlokWrap>
      )}
    </>
  );
};

export default ProductListingUI;
