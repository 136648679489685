"use client";

import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";

import styles from "./../article-category.module.css";
const Navigation = () => {
  const pathname = usePathname();

  const isActive = (path) => {
    if (pathname === "/article" && path === "/article/all") {
      return true;
    }
    return pathname.includes(path);
  };

  return (
    <div className={styles.articles__categories}>
      <Link
        className={clsx(styles.link, isActive("/article/all") && styles.active)}
        href="/article/all"
      >
        All
      </Link>
      <Link
        className={clsx(
          styles.link,
          isActive("/article/wellness") && styles.active
        )}
        href="/article/wellness"
      >
        Wellness
      </Link>
      <Link
        className={clsx(
          styles.link,
          isActive("/article/style") && styles.active
        )}
        href="/article/style"
      >
        Style
      </Link>
      <Link
        className={clsx(
          styles.link,
          isActive("/article/lifestyle") && styles.active
        )}
        href="/article/lifestyle"
      >
        Lifestyle
      </Link>
      <Link
        className={clsx(
          styles.link,
          isActive("/article/travel") && styles.active
        )}
        href="/article/travel"
      >
        Travel
      </Link>
      <Link
        className={clsx(
          styles.link,
          isActive("/article/recipes") && styles.active
        )}
        href="/article/recipes"
      >
        Recipes
      </Link>
      <Link
        className={clsx(
          styles.link,
          isActive("/article/interview") && styles.active
        )}
        href="/article/interview"
      >
        Interview
      </Link>
    </div>
  );
};

export default Navigation;
