import type { TaggedImageSideBySideManualType } from "@/types/storyblok-tagged-image-types";
import dynamic from "next/dynamic";
import ServerSideBySide from "./ServerSideBySide";

const ClientSideBySide = dynamic(() => import("./ClientSideBySide"), {
  ssr: false,
  loading: () => <div>Loading...</div>,
});

const TagSideBySide = ({
  blok,
  preview,
}: {
  blok: TaggedImageSideBySideManualType;
  preview: boolean;
}) => {
  if (preview) return <ClientSideBySide blok={blok} />;

  return <ServerSideBySide blok={blok} />;
};

export default TagSideBySide;
