import StoryblokComponent from "../../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";

import { HeroStoryblokCustom } from "@/types/globals";
import styles from "../hero.module.css";
import OpacityLayer from "./OpacityLayer";

const ImageBlok = ({
  media,
  backgroundOpacity,
  preview
}: {
  media: HeroStoryblokCustom["media"];
  backgroundOpacity: HeroStoryblokCustom["backgroundOpacity"];
  preview?: boolean;
}) =>
  !media || media.length < 1 ? null : (
    <div
      className={styles.images__wrapper}
      style={{ gridTemplateColumns: `repeat(${media.length}, 1fr)` }}
    >
      {media.map((img) => (
        <div className={styles.image__wrapper} key={img._uid}>
          <OpacityLayer value={backgroundOpacity?.value} />
          <StoryblokComponent blok={img} preview={preview} />
        </div>
      ))}
    </div>
  );

export default ImageBlok;
