import formatArticleCard from "@/lib/utils/formatArticleCards";
import { FeaturedArticlesStoryblok } from "@/types/storyblok-blok-types";
import ArticleCard from "../ArticleCard/ArticleCard";
import styles from "./featured-articles.module.css";
import { FetchedFeaturedArticles } from "./fetchFeaturedArticles";

const FeaturedArticles = ({
  blok,
  data
}: {
  blok: FeaturedArticlesStoryblok;
  data: FetchedFeaturedArticles;
}) => {
  const articles = blok.articles.map((id) =>
    typeof id === "string" ? data[id] : id
  );

  return (
    <div className={styles.articles__grid} data-blok>
      {articles.map((article) => (
        <ArticleCard {...formatArticleCard(article)} key={article.full_slug} />
      ))}
    </div>
  );
};

export default FeaturedArticles;
