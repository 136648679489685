"use client";
import { serverFetchPCById } from "@/app/serverFunctionsCentra";
import { Skeleton } from "@/components/atoms";
import { useQuery } from "@tanstack/react-query";
import dynamic from "next/dynamic";
const DynamicTaggedImage = dynamic(() => import("./UI"), {
  loading: () => <Skeleton height="50vh" />,
});

const ClientSideBySide = ({ blok }) => {
  
  const { isLoading, isError, data, error } = useQuery(
    ["side-by-side", blok?.image?.selected],
    async () => {
      const cards = await serverFetchPCById(blok?.image?.selected);
      if (!cards) return [];
      return cards;
    }
  );
  
  if (isLoading && !data) return <p>Loading on client, while in storyblok</p>;
  if (isError) return <p>{`Error while loading: ${error}`}</p>;

  return <DynamicTaggedImage blok={blok} products={data} />;
};

export default ClientSideBySide;
