import { Header, Trigger } from "@radix-ui/react-accordion";
import clsx from "clsx";
import React from "react";

import AccordionPlusIcon from "@/components/icons/AccordionPlusIcon";

import styles from "./accordion-trigger.module.css";

const AccordionTrigger = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLButtonElement>) => (
  <Header className={styles.root}>
    <Trigger className={clsx(styles.trigger, className)} {...props}>
      <span>{children}</span>
      <div className={styles.icon}>
        <AccordionPlusIcon />
      </div>
    </Trigger>
  </Header>
);

export default AccordionTrigger;
