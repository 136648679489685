import dynamic from "next/dynamic";

import { ServerUI } from "../ProductListingUI";

const ProductSliderNoSSR = dynamic(() => import("./ProductSlider"), {
  ssr: false,
  loading: () => <ServerUI />,
});

export default ProductSliderNoSSR;
