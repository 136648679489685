import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

import { Heading, Subtitle } from "@/components/atoms";
import _Link from "@/components/atoms/_Link";
import BlokWrap from "@/components/atoms/BlokWrap";
import getLink from "@/lib/storyblok/utils/getLink";
import type { TitleStoryblok } from "@/types/storyblok-blok-types";

import type { StoryblokLinkBlok } from "../StoryblokLink/StoryblockLink";
import styles from "./title-blok.module.css";

type TitleBlokProps = {
  preamble?: string;
  paragraph?: string;
  extraWide?: boolean;
  large?: boolean;
  CTA?: StoryblokLinkBlok;
};

const formatTitleBlok = (blok: TitleStoryblok): TitleBlokProps => {
  const { preamble, paragraph, extraWide, CTA } = blok;
  return {
    preamble,
    paragraph,
    extraWide,
    CTA: { link: CTA?.[0]?.link, text: CTA?.[0]?.text ?? "" },
    large: false,
  };
};

const TitleBlokUI = ({ blok }: { blok: TitleStoryblok }) => {
  const { CTA, large, preamble, paragraph, extraWide } = formatTitleBlok(blok);
  const link = getLink(CTA?.link);
  if (large)
    return (
      <div
        {...storyblokEditable(blok as unknown as SbBlokData)}
        data-blok
        className={clsx(
          styles.title,
          extraWide && styles.extra__wide,
          "title-blok"
        )}
      >
        {preamble && preamble.length > 1 && <Subtitle>{preamble}</Subtitle>}
        <Heading as="h2" styledAs="h4">
          {paragraph}
        </Heading>
        <_Link href={link ?? "#"}>{CTA?.text}</_Link>
      </div>
    );
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      animated="fadeY"
      data-layout={"narrow"}
      className={clsx(
        styles.title,
        extraWide && styles.extra__wide,
        "title-blok"
      )}
    >
      {preamble && preamble.length > 1 && <Subtitle>{preamble}</Subtitle>}
      <Heading as="h2" styledAs="h4">
        {paragraph}
      </Heading>
      {CTA?.text && (
        <_Link href={link ?? "#"} underlined color={"dark"}>
          {CTA?.text}
        </_Link>
      )}
    </BlokWrap>
  );
};

export default TitleBlokUI;
