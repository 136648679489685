import type { TaggedImageArray } from "./useTaggedImage";

const useTagPosition = () => {
  const infoPosition = (tag: TaggedImageArray[number]) => {
    const { x, y } = tag.position;

    return {
      x,
      y,
    };
  };

  return {
    infoPosition,
  };
};

export default useTagPosition;

export type TagPosition = ReturnType<typeof useTagPosition>;
export type InfoPosition = TagPosition["infoPosition"];
