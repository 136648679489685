import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";
import Link from "next/link";

import { _Link, Heading } from "@/components/atoms";
import BlokWrap from "@/components/atoms/BlokWrap";
import AspectRatioProvider from "@/components/modifiers/AspectRatioProvider";
import getLink from "@/lib/storyblok/utils/getLink";
import type { ArticleGalleryStoryblok } from "@/types/storyblok-blok-types";

import Preamble from "@/components/atoms/Preamble/Preamble";
import StoryblokComponent from "../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";
import styles from "./article-gallery.module.css";

const ArticleGallery = ({ blok }: { blok: ArticleGalleryStoryblok }) => {
  const { articleCard: cards } = blok;
  const Title = () =>
    !blok?.title ? null : (
      <Heading as="h2" styledAs="t2" italic style={{ fontWeight: 100 }}>
        {blok?.title}
      </Heading>
    );
  const BlokPreamble = () =>
    !blok?.preamble ? null : <Preamble>{blok?.preamble}</Preamble>;

  const TitleSection = () =>
    !blok?.title && !blok.preamble ? null : (
      <div className={styles["title-container"]}>
        <BlokPreamble />
        <Title />
        <div />
      </div>
    );
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      animated="custom"
      data-layout={"framed"}
      className={clsx(styles.root, "media-gallery")}
    >
      <TitleSection />
      <div className={styles.grid}>
        {cards &&
          cards.map((item, index) => {
            return (
              <div
                className={clsx(styles.item, "media__gallery__item")}
                key={index}
              >
                <div
                  className={clsx("media__gallery__item__content")}
                  style={{
                    transitionDelay: `${index * 0.15}s`
                  }}
                >
                  {/* image */}
                  <Link href={getLink(item?.link?.[0]?.link)}>
                    <AspectRatioProvider width={1} height={1.5}>
                      <StoryblokComponent blok={item.media[0]} />
                    </AspectRatioProvider>
                    <div className={styles.item__content}>
                      {/* title */}
                      {item.Title && (
                        <Heading
                          as="h3"
                          styledAs="t3"
                          style={{ fontWeight: 100 }}
                        >
                          {item.Title}
                        </Heading>
                      )}
                      {/* button */}
                      {item?.link?.[0]?.text && (
                        <_Link
                          href={getLink(item?.link?.[0]?.link)}
                          size="small"
                          pseudo={true}
                        >
                          <span className={clsx("uppercase", styles.link)}>
                            {item?.link?.[0].text}
                          </span>
                        </_Link>
                      )}
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
      </div>
    </BlokWrap>
  );
};

export default ArticleGallery;
