import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import { Breadcrumbs, Heading } from "@/components/atoms";
import Preamble from "@/components/atoms/Preamble/Preamble";
import StoryblokComponent from "@/lib/storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";
import type { ArticleStoryblok } from "@/types/storyblok-blok-types";

import getMediaSizes from "../Media/getMediaSizes";
import styles from "./article.module.css";

const Article = ({ blok }: { blok: ArticleStoryblok }) => {
  const imageBlok = blok.media?.[0];

  const { aspectRatio } = getMediaSizes(imageBlok);
  return (
    <div
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className="article-blok"
    >
      <div className={styles.hero}>
        <nav className={styles.breadcrumbs__wrapper}>
          <Breadcrumbs
            className={styles.breadcrumbs}
            breadcrumbs={[
              { name: "Journal", uri: "/article/all" },
              {
                name: blok.title?.toLowerCase() ?? "Article",
                uri: "#"
              }
            ]}
            asDiv
          />
        </nav>
        <div className={styles.hero__wrapper}>
          <div className={styles.hero__preambles}>
            {blok.date ? <div>{blok.date}</div> : <div></div>}
            {blok.preamble ? <Preamble>{blok.preamble}</Preamble> : <div></div>}
            {blok.author ? <div>{blok.author}</div> : <div></div>}
          </div>

          {blok.title && (
            <Heading as="h1" styledAs="h2" className={styles.hero__title}>
              {blok.title}
            </Heading>
          )}
        </div>
        {imageBlok && (
          <div
            className={styles.image}
            style={{
              // @ts-ignore - this is totally fine, just TS being a bit too strict
              "--mobile-image-aspect-ratio": aspectRatio.mobile,
              "--desktop-image-aspect-ratio": aspectRatio.desktop
            }}
            data-blok
            data-blok-background
            data-layout={"full"}
          >
            <StoryblokComponent blok={imageBlok} />
          </div>
        )}
      </div>
      {blok.body?.map((blok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </div>
  );
};

export default Article;
