"use client";

import useLocation from "@/lib/hooks/useLocation";

import useChangeLocation from "./useChangeLocation";

const scandi = ["NO", "SE", "DK", "FI"];

const useLocationSelector = (search: string = "") => {
  const location = useLocation();
  const { changeLocation } = useChangeLocation();

  if (!location) console.error("No location", location);

  const countries = location?.countries
    ?.filter((c) => c.name.toLowerCase().includes(search.toLowerCase()))
    .map((country) => {
      return {
        ...country,
        select: () => changeLocation.mutate({ locationId: country?.country }),
        selected: location?.location === country?.country,
      };
    });

  const eu =
    countries?.filter(
      (country) => country.eu === true && !scandi.includes(country.country)
    ) ?? [];
  const rest =
    countries?.filter(
      (country) => country.eu === false && !scandi.includes(country.country)
    ) ?? [];
  const scandinavia =
    countries?.filter((country) => scandi.includes(country.country)) ?? [];

  return {
    changeLocation,
    countries: [...scandinavia, ...eu, ...rest],
    scandinavia,
    eu,
    rest,
    location,
    loading: location?.isLoading,
  };
};

export default useLocationSelector;
