import { RichtextStoryblok } from "@/types/storyblok-blok-types";

/**
 * @description Check if a string is empty
 * @param str string to check
 * @returns {boolean} true if empty
 */

const isEmptyString = (str: RichtextStoryblok | string | undefined) =>
  str === undefined || str === null || str === "";

export default isEmptyString;
