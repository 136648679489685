import dynamic from "next/dynamic";

const ClientComponentWithFetcherNoSSR = dynamic(
  () => import("./ClientComponentWithFetcher"),
  {
    ssr: false,
    loading: () => <p>Loading...</p>
  }
);

export default ClientComponentWithFetcherNoSSR;
