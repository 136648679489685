import FormatStoryblokMedia from "@/lib/storyblok/utils/Media/FormatStoryblokMedia";
import { MediaElementStoryblok } from "@/types/storyblok-blok-types";

const getMediaSizes = (blok: MediaElementStoryblok) => {
  const { desktop: desktopMedia, mobile: mobileMedia } =
    FormatStoryblokMedia(blok);
  const sizes = {
    desktop: {
      width: parseInt(desktopMedia?.width ?? "4000"),
      height: parseInt(desktopMedia?.height ?? "4000")
    },
    mobile: {
      width: parseInt(mobileMedia?.width ?? "2000"),
      height: parseInt(mobileMedia?.height ?? "2000")
    }
  };
  const aspectRatio = {
    desktop: sizes.desktop.width / sizes.desktop.height,
    mobile: sizes.mobile.width / sizes.mobile.height
  };
  return { aspectRatio, sizes, desktopMedia, mobileMedia };
};

export default getMediaSizes;
