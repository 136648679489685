import type { SideBySideStoryblok } from "@/types/storyblok-blok-types";

import UI from "./UI";

const SideBySide = ({
  blok,
  preview,
}: {
  blok: SideBySideStoryblok;
  preview: boolean;
}) => {
  return <UI blok={blok} preview={preview} />;
};

export default SideBySide;
