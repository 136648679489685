"use client";

import { useQuery } from "@tanstack/react-query";
import { forwardRef } from "react";
import { BlokParameters } from "../../blokSetup/blok-component-types";
import { getBlokComponent } from "../../blokSetup/bloks";
import { StoryblokComponentProps } from "../StoryblokComponent";

const ClientComponentWithFetcher = forwardRef<
  HTMLElement,
  StoryblokComponentProps<BlokParameters> & {
    fetcher: (arg0: any) => Promise<any>;
  }
>(({ blok, preview, sidebyside, fetcher, ...restProps }, ref) => {
  const Component = getBlokComponent(blok.component)?.blok;
  const { data } = useQuery(["blok", blok], () => fetcher(blok));

  if (!Component) {
    const errorMessage = `Component: "${blok.component}" not found`;
    return <div>{errorMessage}</div>;
  }

  return (
    <Component
      ref={ref}
      blok={blok}
      {...restProps}
      sidebyside={sidebyside}
      data={data}
      preview={preview}
    />
  );
});
ClientComponentWithFetcher.displayName = "StoryblokClientComponentWithFetcher";

export default ClientComponentWithFetcher;
