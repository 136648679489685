import { Heading } from "@/components/atoms";
import styles from "@/lib/bloks/Body/body.module.css";

interface HeadingProps {
  children: React.ReactNode;
  level: number;
  alignment?: string;
}

const BodyHeading = ({ children, level, alignment }: HeadingProps) => {
  if (level === 1) {
    return (
      <Heading className={`${styles?.[alignment]}`} as="h2" styledAs="t1">
        {children}
      </Heading>
    );
  }
  if (level === 2) {
    return (
      <Heading className={`${styles?.[alignment]}`} as="h3" styledAs="t2">
        {children}
      </Heading>
    );
  }
  if (level === 3) {
    return (
      <Heading className={`${styles?.[alignment]}`} as="h4" styledAs="t3">
        {children}
      </Heading>
    );
  }
  if (level === 4) {
    return (
      <Heading className={`${styles?.[alignment]}`} as="h5" styledAs="t4">
        {children}{" "}
      </Heading>
    );
  }

  return (
    <Heading className={`${styles?.[alignment]}`} as="h2" styledAs="t1">
      {children}
    </Heading>
  );
};

export default BodyHeading;
