import clsx from "clsx";

import BlokWrap from "@/components/atoms/BlokWrap";
import type { SideBySideStoryblok } from "@/types/storyblok-blok-types";

import StoryblokComponent from "../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";
import styles from "./side-by-side.module.css";

const UI = ({
  blok,
  preview
}: {
  blok: SideBySideStoryblok;
  preview: boolean;
}) => {
  const { hero, reversed, content } = blok;
  const heroBlok = hero?.[0];

  return (
    <BlokWrap
      className={clsx(
        "side-by-side",
        styles.root,
        reversed ? styles.right__side : styles.left__side,
        reversed ? "right" : "left"
      )}
      data-half
      data-blok
      data-layout="full"
      animated="custom"
    >
      <div className={styles.content__container}>
        {content &&
          content.map((blok, i) => (
            <div data-blok className={styles.content__item} key={i}>
              <StoryblokComponent
                blok={blok}
                preview={preview}
                sidebyside={true}
              />
            </div>
          ))}
      </div>
      <div className={`${styles.hero__container} side-by-side-media`}>
        <div className={styles.hero__item}>
          <StoryblokComponent blok={heroBlok} preview={preview} />
        </div>
      </div>
    </BlokWrap>
  );
};

export default UI;
