import type { MultilinkStoryblok } from "@/types/storyblok-blok-types";

const formatUrlLink = (link: MultilinkStoryblok) => {
  if ("url" in link) return link.url;
  throw new Error("Link is not an asset or url link");
};

const checkIfInfoPage = (link: string) => {
  const infoPagesRegex = /\/info-pages(\/.+)$/i;
  const isInfoPage = link.match(infoPagesRegex);
  const url = isInfoPage && isInfoPage[1] ? `/${isInfoPage[1]}` : null;

  return url;
};

const checkIfHome = (link: string) => {
  const homeRegex = /^\/?home(?![a-zA-Z])/i;
  const isHome = link.match(homeRegex);
  const url = isHome && isHome[0] ? "/" : null;

  return url;
};
const getStoryUrl = (link: MultilinkStoryblok) => {
  if ("story" in link) return link.story?.full_slug || "";
  if (link.linktype === "story") return link.cached_url || "";
  throw new Error("Link is not a story link");
};

const formatStoryLink = (link: MultilinkStoryblok) => {
  const url = getStoryUrl(link);
  if (checkIfInfoPage(url)) return checkIfInfoPage(url) as string;
  if (checkIfHome(url)) return checkIfHome(url) as string;
  return url.startsWith("/") ? url : `/${url}`;
};

type EmailLink = {
  email?: string;
  linktype?: "email";
};

const isEmail = (link: MultilinkStoryblok): link is EmailLink => {
  return (link as EmailLink)?.linktype === "email";
};

const getLink = (link: MultilinkStoryblok | undefined) => {
  if (link?.linktype === "url" || link?.linktype === "asset") {
    return formatUrlLink(link);
  }
  if (link?.linktype === "story") {
    return formatStoryLink(link);
  }

  if (isEmail(link)) {
    return link?.email ? `mailto:${link?.email}` : "";
  }

  return "";
};

export const getLinkTarget = (link: any) => {
  if (!link) return;
  if (isEmail(link)) return;
  return link?.target;
};

export default getLink;
