import { Accordion as UIAccordion } from "@/components/atoms/Accordion";
import type { AccordionItemStoryblok } from "@/types/storyblok-blok-types";
import { render } from "storyblok-rich-text-react-renderer";
import styles from "./accordion.module.css";

const AccordionItem = ({ item }: { item: AccordionItemStoryblok }) => {
  return (
    <UIAccordion
      styleProps={{
        accordion: styles.accordion,
        trigger: styles["accordion-trigger"]
      }}
      title={item.title}
      icon={<span>+</span>}
    >
      {item.text && render(item.text)}
    </UIAccordion>
  );
};

export default AccordionItem;
