import { forwardRef } from "react";
import { BlokParameters } from "../blokSetup/blok-component-types";
import { getBlokComponent } from "../blokSetup/bloks";
import { BaseBlokParameters } from "../typeGenerators";
import ClientComponentNoSSR from "./ClientComponent/ClientComponentNoSSR";
import ClientComponentWithFetcherNoSSR from "./ClientComponentWithFetcher/ClientComponentWithFetcherNoSSR";
import ServerComponent from "./ServerComponent";

export type StoryblokComponentProps<
  BlokParameters extends BaseBlokParameters,
  T = BlokParameters[keyof BlokParameters]
> = T extends BlokParameters[keyof BlokParameters]
  ? {
      blok: T;
      preview?: boolean;
      [key: string]: unknown;
    }
  : never;

const StoryblokComponent = forwardRef<
  HTMLElement,
  StoryblokComponentProps<BlokParameters>
>(({ blok, preview = false, sidebyside, articles, ...restProps }, ref) => {
  if (!blok) {
    const errorMessage = "Please provide a blok";
    console.error(errorMessage);
    return <div>{errorMessage}</div>;
  }

  if (!blok.component) {
    const errorMessage = `Component is not listed in bloks`;
    console.error(errorMessage);
    return <div>{errorMessage}</div>;
  }

  if (preview) {
    const component = getBlokComponent(blok.component);

    if (component && typeof component === "object" && "fetcher" in component) {
      return (
        <ClientComponentWithFetcherNoSSR
          key={blok.component}
          blok={blok}
          preview={preview}
          ref={ref}
          {...restProps}
          sidebyside={sidebyside}
          articles={articles}
          fetcher={component.fetcher}
        />
      );
    }
    return (
      <ClientComponentNoSSR
        key={blok.component}
        blok={blok}
        preview={preview}
        sidebyside={sidebyside}
        articles={articles}
        {...restProps}
        ref={ref}
      />
    );
  }
  return (
    <>
      <ServerComponent
        key={blok.component}
        blok={blok}
        {...restProps}
        sidebyside={sidebyside}
        articles={articles}
        ref={ref}
      />
    </>
  );
});
StoryblokComponent.displayName = "StoryblokComponent";

export default StoryblokComponent;
