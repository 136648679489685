import { ArticleCardType } from "../bloks/ArticleCard/ArticleCard";
import { ArticleCardProps } from "../bloks/ArticleCategory/ArticleCategory";

const formatArticleCard = (article: ArticleCardProps): ArticleCardType => {
  return {
    name: article.name,
    image: article.content?.media[0],
    slug: article.full_slug,
    preamble: article.content?.preamble,
  };
};

export default formatArticleCard;
