import * as Accordion from "@radix-ui/react-accordion";
import clsx from "clsx";

import useLocationSelector from "@/lib/hooks/useLocationSelector";

import styles from "../../header-bloks.module.css";
import AccordionContent from "../Accordion/Content";
import AccordionTrigger from "../Accordion/Trigger";

const ChangeLocation = () => {
  const { changeLocation, countries } = useLocationSelector();

  return (
    <Accordion.Item value={"change country"}>
      <AccordionTrigger>{"CHANGE COUNTRY"}</AccordionTrigger>
      <AccordionContent>
        {countries &&
          countries?.map((country) => (
            <button
              data-active={country.selected}
              className={clsx(styles.accordion__link, styles.change__country)}
              key={country.code}
              onClick={() =>
                changeLocation.mutate({
                  locationId: country?.country,
                })
              }
            >
              {country?.name}
            </button>
          ))}
      </AccordionContent>
    </Accordion.Item>
  );
};

export default ChangeLocation;
