import { CollectionPreviewStoryblok } from "@/types/storyblok-blok-types";
import { SbBlokData, storyblokEditable } from "@storyblok/js";
import clsx from "clsx";

import { _Link } from "@/components/atoms";
import getLink from "@/lib/storyblok/utils/getLink";
import ServerProductListing from "./ServerProductListing";
import styles from "./collection-preview.module.css";

const CollectionPreview = ({ blok }: { blok: CollectionPreviewStoryblok }) => {
  const CTA = () => {
    if (!blok.link) return null;
    const link = blok.link?.[0];
    const url = getLink(link.link);
    const text = link.text;
    return (
      <_Link href={url} size="large" underlined color="dark" icon="plus">
        {text}
      </_Link>
    );
  };
  return (
    <div
      {...storyblokEditable(blok as unknown as SbBlokData)}
      data-blok
      data-layout="page"
      className={clsx("collection-preview", styles.root)}
    >
      <div className={styles.left}>
        <div className={styles.details}>
          <h2 className={styles.title}>{blok.title}</h2>
          <div className={styles.description}>{blok.description}</div>
          <CTA />
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.product__grid}>
          <ServerProductListing blok={blok} />
        </div>
      </div>
    </div>
  );
};

export default CollectionPreview;
