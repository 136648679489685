import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

import type { DenimSectionStoryblok } from "@/types/storyblok-blok-types";

import StoryblokComponent from "../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";
import styles from "./denim-blok.module.css";

const DenimSection = ({
  blok,
  preview
}: {
  blok: DenimSectionStoryblok;
  preview: boolean;
}) => {
  return (
    <div
      {...storyblokEditable(blok as unknown as SbBlokData)}
      data-blok
      data-layout="full"
      data-blok-background
      className={clsx("denim", styles.denim__section)}
    >
      {blok.Bloks.map((childBlok) => (
        <StoryblokComponent
          blok={childBlok}
          key={childBlok._uid}
          preview={preview}
        />
      ))}
    </div>
  );
};

export default DenimSection;
