import dynamic from "next/dynamic";
import Link from "next/link";

import styles from "./../article-category.module.css";
const Navigation = dynamic(() => import("./Navigation"), {
  loading: () => (
    <div className={styles.articles__categories}>
      <Link href="/article">All</Link>
      <Link href="/article/wellness">Wellness</Link>
      <Link href="/article/style">Style</Link>
      <Link href="/article/lifestyle">Lifestyle</Link>
      <Link href="/article/travel">Travel</Link>
      <Link href="/article/recipes">Recipes</Link>
      <Link href="/article/interview">Interview</Link>
    </div>
  ),
});

export default Navigation;
