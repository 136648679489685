"use client";

import { type HTMLAttributes } from "react";
import { useInView } from "react-intersection-observer";

export type AnimationType = "fadeY" | "custom" | "";
type DivProps = HTMLAttributes<HTMLDivElement> & { animated: AnimationType };

const BlokWrap = (props: DivProps) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  if (props.animated) {
    return (
      <div
        ref={ref}
        data-blok
        data-animated={props.animated}
        data-view={inView}
        {...props}
      />
    );
  }

  return <div data-blok {...props} />;
};

export default BlokWrap;
