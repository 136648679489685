const AccordionPlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={7}
      height={13}
      viewBox="0 0 7 13"
    >
      <text
        id="_"
        data-name="+"
        transform="translate(7 10)"
        fontSize={11}
        fontFamily="NHaasGroteskDSPro-55Rg, Neue Haas Grotesk Display Pro"
        letterSpacing="0.04em"
      >
        <tspan x="-6.16" y={0}>
          +
        </tspan>
      </text>
    </svg>
  );
};

export default AccordionPlusIcon;
