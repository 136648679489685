"use client";

import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

import {
  getStoryblokHeight,
  getStoryblokWidth
} from "@/lib/storyblok/utils/Media/mediaFormatters";
import type { AssetStoryblok } from "@/types/storyblok-blok-types";
import type { TaggedImageSideBySideManualType } from "@/types/storyblok-tagged-image-types";

import useTaggedImage from "./hooks/useTaggedImage";
import useTagPosition from "./hooks/useTagPosition";
import Tag from "./Tag";
import styles from "./taggedImage.module.css";

const UI = ({
  blok,
  products
}: {
  blok: TaggedImageSideBySideManualType;
  products: any;
}) => {
  const tags = useTaggedImage(products, blok?.image?.tags);

  const { infoPosition } = useTagPosition();
  if (!tags) return null;

  const width = getStoryblokWidth({
    filename: blok.image.media.file
  } as AssetStoryblok);
  const height = getStoryblokHeight({
    filename: blok.image.media.file
  } as AssetStoryblok);

  const newWidth = +width / 10;
  const newHeight = +height / 10;

  return (
    <div
      data-blok
      key={blok._uid}
      className={styles.wrapper}
      {...storyblokEditable(blok as unknown as SbBlokData)}
    >
      <div className={styles["text-column"]}></div>

      <div className={styles.container}>
        {tags &&
          tags.length > 0 &&
          tags?.map((tag, i) => (
            <Tag
              key={`tag-${i}-${tag.id}`}
              tag={tag}
              infoPosition={infoPosition}
              index={i}
            />
          ))}

        {blok?.image?.media && (
          //TODO: convert this to use next image
          // eslint-disable-next-line @next/next/no-img-element

          <Image
            alt=""
            className={styles.image}
            sizes={`(max-width: 1024px) 70vw, (max-width: 2560px) 50vw`}
            src={blok.image.media.file}
            width={newWidth}
            height={newHeight}
            style={{
              // Other styles moved to .image class
              maxHeight: `${80 * (newHeight / newWidth)}vw`
            }}
          />
        )}
      </div>
      <div className={clsx(styles["text-column"])}>
        {tags.map((tag, i) => (
          <Link
            key={i}
            className={clsx(styles.tag)}
            href={`/product/${tag?.product?.core?.slug}`}
          >
            <p> {i + 1}</p>
            <p className="underline-hover">
              {tag?.product?.core?.name?.toLocaleUpperCase()},{" "}
              {tag?.product?.core?.variantName}
            </p>
            <span></span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default UI;
