import Link from "next/link";

import type { TaggedImageType } from "../hooks/useTaggedImage";
import type { InfoPosition } from "../hooks/useTagPosition";
import styles from "./tag.module.css";
const UI = ({
  tag,
  index,
}: {
  tag?: TaggedImageType;
  infoPosition: InfoPosition;
  index: number;
}) => {
  if (!tag) return null;
  const { product, position } = tag;

  if (!product) return null;

  return (
    <>
      <Link
        href={`/product/${product?.core?.slug}`}
        className={`${styles.tag} ${tag.selected && styles.selected} ${
          tag.inactive && styles.inactive
        }`}
        style={{
          top: `${position.y}%`,
          left: `${position.x}%`,
        }}
      >
        <p>{index + 1}</p>
      </Link>
    </>
  );
};

export default UI;
