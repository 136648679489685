import type {
  ExtraBlokFields,
  StoryblokTypes
} from "@/lib/storyblok/storyblokSetup/blokSetup/blok-component-types";

import Accordion from "@/lib/bloks/Accordion";
import fetchFeaturedArticles from "@/lib/bloks/FeaturedArticles/fetchFeaturedArticles";
import Article from "../../../bloks/Article/Article";
import ArticleCategory from "../../../bloks/ArticleCategory/ArticleCategory";
import ArticleGallery from "../../../bloks/ArticleGallery/ArticleGallery";
import ArticlesShowcase from "../../../bloks/ArticlesShowcase";
import Body from "../../../bloks/Body";
import Button from "../../../bloks/Button/Button";
import CollectionPreview from "../../../bloks/CollectionPreview/CollectionPreview";
import DenimSection from "../../../bloks/DenimSection";
import FeaturedArticles from "../../../bloks/FeaturedArticles/FeaturedArticles";
import Hero from "../../../bloks/Hero/Hero";
import HeroSection from "../../../bloks/HeroSection";
import Media from "../../../bloks/Media";
import MediaElement from "../../../bloks/MediaElement";
import MediaGallery from "../../../bloks/MediaGallery/MediaGallery";
import NewsletterSignup from "../../../bloks/NewsletterSignup/NewsletterSignup";
import Plp from "../../../bloks/PLP";
import Page from "../../../bloks/Page/Page";
import ProductListing from "../../../bloks/ProductListing";
import fetchProductListing from "../../../bloks/ProductListing/fetchProductListing";
import SideBySide from "../../../bloks/SideBySide/SideBySide";
import StorePage from "../../../bloks/StorePage/Page";
import TagSideBySide from "../../../bloks/TagSideBySide";
import TitleBlock from "../../../bloks/TitleBlok/TitleBlock";
import HeaderBloks from "../../../bloks/statics/header/HeaderBloks";
import { BuildBlokComponents, BuildBlokParameters } from "../typeGenerators";

const Return = () => {
  return (
    <iframe
      data-blok
      id="return-portal"
      loading="lazy"
      style={{
        minHeight: "900px",
        backgroundColor: "var(--color-background)",
        borderTop: "60px solid #fff"
      }}
      src="https://app.shiphero.com/returns/4347/?language=en"
      width="100%"
      height="100%"
      frameBorder="0"
    ></iframe>
  );
};

const components: BuildBlokComponents<StoryblokTypes, ExtraBlokFields> = {
  header: HeaderBloks,
  title: TitleBlock,
  page: Page,
  mediaGallery: MediaGallery,
  heroSection: HeroSection,
  productListing: { blok: ProductListing, fetcher: fetchProductListing },
  hero: Hero,
  sideBySide: SideBySide,
  media: Media,
  mediaElement: MediaElement,
  body: Body,
  denimSection: DenimSection,
  taggedImageSideBySide: TagSideBySide,
  Article: Article,
  productListingPage: Plp,
  articleGallery: ArticleGallery,
  button: Button,
  newsletterSignup: NewsletterSignup,
  articleCategory: ArticleCategory,
  articlesShowcase: ArticlesShowcase,
  return: Return,
  featuredArticles: {
    blok: FeaturedArticles,
    fetcher: fetchFeaturedArticles
  },
  collectionPreview: CollectionPreview,
  store: StorePage,
  accordion: Accordion
};

export const buildGetBlokComponent =
  <
    BlokParameters,
    BlokComponents extends {
      [component in keyof BlokParameters]: any;
    }
  >(
    blokMap: BlokComponents
  ) =>
  (key: keyof BlokParameters) => {
    return blokMap[key] as any;
  };

export const getBlokComponent = buildGetBlokComponent<
  BuildBlokParameters<StoryblokTypes>,
  BuildBlokComponents<StoryblokTypes, ExtraBlokFields>
>(components);

export default components;
