import clsx from "clsx";
import Link from "next/link";

import getLink from "@/lib/storyblok/utils/getLink";
import type { MenuSectionStoryblok } from "@/types/storyblok-blok-types";

import styles from "./header-bloks.module.css";

const MenuSection = (section: MenuSectionStoryblok) => {
  const maxLinksPerColumn = 7;
  const sectionLinks = section.links ? section.links.length : 0;
  const sectionSpanClass =
    sectionLinks > maxLinksPerColumn ? styles.spanTwoColumns : "";

  const title = `( ${section.title} )`;

  return (
    <div className={clsx(styles.linkMenuSection, sectionSpanClass)}>
      <h3 className={styles.linkMenuTitle}>{title}</h3>
      <div className={styles.linkSection}>
        {section.links &&
          section.links.map((link) => {
            const href = getLink(link.link);
            return href ? (
              <Link href={href} key={link._uid} className={styles.subMenuLink}>
                <span className="underline-hover">{link.text}</span>
              </Link>
            ) : (
              <></>
            );
          })}
      </div>
    </div>
  );
};

export default MenuSection;
