import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import BlokWrap from "@/components/atoms/BlokWrap";
import Inset from "@/components/atoms/Inset";
import type { HeroSectionStoryblok } from "@/types/storyblok-blok-types";

import StoryblokComponent from "../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";
import styles from "./hero-section.module.css";

const HeroSection = ({
  blok,
  preview
}: {
  blok: HeroSectionStoryblok;
  preview?: boolean;
}) => {
  const { hero: heros } = blok;
  if (!heros) return <></>;

  // not sure if this is actually used now.
  const low = false;
  const inset = false;

  // how many of the child heroes have inset enabled
  const heroesWithInset = heros
    .map((hero) => hero.inset)
    .filter(Boolean).length;

  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      animated="custom"
      data-blok-background
      data-layout={"full"}
      className={`hero-container ${low ? styles.heroLow : styles.hero}`}
    >
      {/* not sure if this Inset component is actually used now.  */}
      <Inset active={inset}>
        <div
          className={`${styles.container}`}
          data-length={heros.length}
          data-double-inset={heroesWithInset > 1}
        >
          {heros.map((hero) => (
            <StoryblokComponent
              blok={hero}
              key={hero._uid}
              preview={preview}
              length={heros.length}
            />
          ))}
        </div>
      </Inset>
    </BlokWrap>
  );
};

export default HeroSection;
