import type { ProductListingPageStoryblok } from "@/types/storyblok-blok-types";

import StoryblokComponent from "../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";

const Plp = ({
  blok,
  preview = false
}: {
  blok: ProductListingPageStoryblok;
  preview?: boolean;
}) => {
  if (!blok) return <></>;
  return (
    <>
      {blok.Title?.map((titleBloks) => (
        <StoryblokComponent
          blok={titleBloks}
          key={titleBloks._uid}
          preview={preview}
        />
      ))}
    </>
  );
};

export default Plp;
