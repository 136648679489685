import type { SbBlokData } from "@storyblok/js";
import { storyblokEditable } from "@storyblok/js";

import BlokWrap from "@/components/atoms/BlokWrap";
import type { ButtonStoryblok } from "@/types/storyblok-blok-types";

import StoryblokLink from "../StoryblokLink";
import styles from "./button.module.css";
const Button = ({ blok }: { blok: ButtonStoryblok }) => {
  const { link, align } = blok;

  const alignment = () => {
    if (align === "center") {
      return "center";
    }
    if (align === "right") {
      return "flex-end";
    }
    return "flex-start";
  };
  return (
    <BlokWrap
      animated=""
      {...storyblokEditable(blok as unknown as SbBlokData)}
      data-layout={"framed"}
      className={styles.wrapper}
      style={{
        alignItems: alignment(),
      }}
    >
      {link?.[0] && (
        <StoryblokLink
          blok={{ link: link?.[0].link, text: link?.[0].text }}
          darkText
        />
      )}
    </BlokWrap>
  );
};

export default Button;
