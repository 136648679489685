import { ButtonLink, _Link } from "@/components/atoms";

interface UIProps {
  text: string;
  link: string;
  className?: string;
  darkText?: boolean;
  asLink?: boolean;
  target?: "_blank" | "_self";
}

const UI = ({ text, link, darkText, asLink = false, target }: UIProps) => {
  if (asLink)
    return (
      <_Link
        href={link}
        underlined
        color={darkText ? "dark" : "light"}
        target={target}
      >
        <span className="uppercase">{text}</span>
      </_Link>
    );
  return (
    <ButtonLink
      href={link}
      color="transparent"
      outlined
      textColor={darkText ? "black" : "white"}
      target={target}
    >
      <span className="uppercase">{"+"}</span>
      <span className="uppercase">{text}</span>
    </ButtonLink>
  );
};

export default UI;
