"use client";

import { forwardRef } from "react";
import { BlokParameters } from "../../blokSetup/blok-component-types";
import { getBlokComponent } from "../../blokSetup/bloks";
import { StoryblokComponentProps } from "../StoryblokComponent";

const ClientComponent = forwardRef<
  HTMLElement,
  StoryblokComponentProps<BlokParameters>
>(({ blok, preview, sidebyside, ...restProps }, ref) => {
  const Component = getBlokComponent(blok.component);
  if (!Component) {
    const errorMessage = `Component: "${blok.component}" not found`;
    return <div>{errorMessage}</div>;
  }

  return (
    <Component
      ref={ref}
      blok={blok}
      preview={preview}
      sidebyside={sidebyside}
      {...restProps}
    />
  );
});
ClientComponent.displayName = "StoryblokClientComponent";

export default ClientComponent;
