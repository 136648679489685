import type { HeroStoryblok } from "@/types/storyblok-blok-types";

import { HeroUI } from "./UI";

const Hero = ({
  blok,
  preview,
  length
}: {
  blok: HeroStoryblok;
  preview: boolean;
  length?: number;
}) => {
  return <HeroUI blok={blok} length={length ? length : 1} preview={preview} />;
};

export default Hero;
