import { forwardRef } from "react";
import { BlokParameters } from "../blokSetup/blok-component-types";
import { getBlokComponent } from "../blokSetup/bloks";
import { StoryblokComponentProps } from "./StoryblokComponent";

const ServerComponent = forwardRef<
  HTMLElement,
  StoryblokComponentProps<BlokParameters>
>(async ({ blok, sidebyside, articles, ...restProps }, ref) => {
  const Component = getBlokComponent(blok.component);
  if (!Component) {
    const errorMessage = `Component: "${blok.component}" not found`;
    return <div>{errorMessage}</div>;
  }
  if (Component && typeof Component === "object" && "fetcher" in Component) {
    const data = await Component.fetcher(blok);
    const Blok = Component.blok;
    return (
      <Blok
        ref={ref}
        blok={blok}
        {...restProps}
        articles={articles}
        sidebyside={sidebyside}
        data={data}
      />
    );
  }
  if (Component)
    return (
      <Component
        ref={ref}
        blok={blok}
        {...restProps}
        articles={articles}
        sidebyside={sidebyside}
      />
    );

  return <div>Unknown error while rendering blok: {blok._uid}</div>;
});

ServerComponent.displayName === "ServerComponent";

export default ServerComponent;
