import {
  MARK_ITALIC,
  NODE_PARAGRAPH,
  render
} from "storyblok-rich-text-react-renderer";

import { RichtextStoryblok } from "@/types/storyblok-blok-types";
import clsx from "clsx";
import styles from "./rich-title.module.css";

interface RichTitleProps {
  children: RichtextStoryblok | string;
  className?: string;
}
const RichTitle = ({ children, className = "" }: RichTitleProps) => {
  return (
    <div className={className}>
      {render(children, {
        nodeResolvers: {
          [NODE_PARAGRAPH]: (children) => (
            <span className={clsx(className, styles.title)}>{children}</span>
          )
        },
        markResolvers: {
          [MARK_ITALIC]: (children) => <i>{children}</i>
        }
      })}
    </div>
  );
};

export default RichTitle;
