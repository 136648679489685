"use client";

import { useEffect } from "react";

const DenimBodyUpdater = ({ isDenim }: { isDenim: boolean }) => {
  useEffect(() => {
    if (isDenim) {
      document.body.classList.add("denim");
    }
    return () => {
      document.body.classList.remove("denim");
    };
  }, [isDenim]);
  return <></>;
};

export default DenimBodyUpdater;
