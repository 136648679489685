import { create } from "zustand";

import type { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";

export interface ITag {
  x: number;
  y: number;
  id: number;
  name: string;
}

type TagStore = {
  tag: MobileTagType | null;
  setTag: (tag: MobileTagType) => void;
  removeTag: () => void;
};
export const useTagStore = create<TagStore>((set) => ({
  tag: null,
  setTag: (tag: MobileTagType) => set({ tag }),
  removeTag: () => set({ tag: null }),
}));

export type MobileTagType = {
  x: number;
  y: number;
  id: number;
  name: string;
  product: ProductCard | undefined;
  select: () => void;
  selected: boolean;
};

const useTaggedImage = (products: ProductCard[], tags?: ITag[]) => {
  const selected = useTagStore((state) => state.tag);
  const setTag = useTagStore((state) => state.setTag);
  const removeTag = useTagStore((state) => state.removeTag);

  const findProduct = (tag: ITag) => {
    return products?.find((product) => product?.id && +product.id === +tag?.id);
  };
  const newTags = tags?.map((tag: ITag) => {
    return {
      position: {
        x: tag.x,
        y: tag.y,
      },
      id: tag.id,
      select:
        selected?.id === tag.id
          ? () => removeTag()
          : () =>
              setTag({
                ...tag,
                product: findProduct(tag),
                select: removeTag,
                selected: true,
              }),
      selected: selected?.id === tag.id,
      inactive: selected !== null && selected?.id !== tag.id,
      product: products?.find(
        (product) => product?.id && +product.id === +tag.id
      ),
    };
  });

  return newTags || [];
};

export default useTaggedImage;

export type TaggedImageArray = ReturnType<typeof useTaggedImage>;
export type TaggedImageType = TaggedImageArray[number];
