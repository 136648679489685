import styles from "../hero.module.css";

const OpacityLayer = ({ value }: { value: number | undefined }) => {
  return (
    <div
      className={styles.opacity__layer}
      style={{
        background: `rgba(0, 0, 0, ${(value ?? 0) / 100})`
      }}
    ></div>
  );
};

export default OpacityLayer;
