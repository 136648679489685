import { Skeleton } from "@/components/atoms";
import { fetchPCById } from "@/lib/centra/fetchersSetup";
import type { TaggedImageSideBySideManualType } from "@/types/storyblok-tagged-image-types";
import dynamic from "next/dynamic";
const DynamicTaggedImage = dynamic(() => import("./UI"), {
  loading: () => <Skeleton height="50vh" />,
});

const ServerSideBySide = async ({
  blok,
}: {
  blok: TaggedImageSideBySideManualType;
}) => {
  const products = blok?.image?.selected
    ? await Promise.all(
        blok?.image?.selected?.map(async (id: string) => {
          const product = await fetchPCById(id);
          return product;
        })
      )
    : [];

  return <DynamicTaggedImage blok={blok} products={products} />;
};

export default ServerSideBySide;
