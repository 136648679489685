import { storyblokEditable, type SbBlokData } from "@storyblok/react/rsc";

import getLink, { getLinkTarget } from "@/lib/storyblok/utils/getLink";
import type { MultilinkStoryblok } from "@/types/storyblok-blok-types";

import UI from "./UI";

export interface StoryblokLinkBlok extends SbBlokData {
  link: MultilinkStoryblok | undefined;
  text: string;
}

export interface StoryblokLinkProps {
  blok?: StoryblokLinkBlok;
  className?: string;
  darkText?: boolean;
  asLink?: boolean;
}

const StoryblokLink = ({
  blok,
  className,
  darkText,
  asLink
}: StoryblokLinkProps) => {
  if (!blok?.link) return null;
  const link = getLink(blok.link);
  const target = getLinkTarget(blok.link);

  const { text } = blok;
  return (
    <UI
      {...storyblokEditable(blok as unknown as SbBlokData)}
      text={text}
      link={link ?? ""}
      className={className}
      darkText={darkText}
      asLink={asLink}
      target={target}
    />
  );
};

export default StoryblokLink;
