"use client";
import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";
import Image from "next/image";

import BlokWrap from "@/components/atoms/BlokWrap";
import type { MediaStoryblok } from "@/types/storyblok-blok-types";

import getMediaSizes from "./getMediaSizes";
import styles from "./media.module.css";

type deviceMedia =
  | {
      isImage: boolean;
      isVideo: boolean;
      file: string;
      alt: string;
      width: string | undefined;
      height: string | undefined;
      focus: string;
      _uid: string;
    }
  | undefined;
type Sizes = {
  desktop: {
    width: number;
    height: number;
  };
  mobile: {
    width: number;
    height: number;
  };
};

const FixedMediaElement = ({
  desktopMedia,
  mobileMedia,
  full,
  sizes,
  aspectRatio
}: {
  desktopMedia: deviceMedia;
  mobileMedia: deviceMedia;
  sizes: Sizes;
  full: boolean;
  aspectRatio: {
    desktop: number;
    mobile: number;
  };
}) => {
  const hasDesktopImage =
    !!desktopMedia?.file && desktopMedia.isImage && !desktopMedia.isVideo;
  const hasMobileImage =
    !!mobileMedia?.file && mobileMedia.isImage && !mobileMedia.isVideo;

  return (
    <>
      {hasDesktopImage && (
        <div
          className={clsx(styles.desktop, styles.media_positioner)}
          data-no-mobile={!hasMobileImage}
          style={{
            aspectRatio: `${aspectRatio.desktop} / 1`
          }}
        >
          <Image
            src={desktopMedia.file}
            alt={desktopMedia.alt}
            data-no-mobile={!hasMobileImage}
            width={full ? 4000 : sizes.desktop.width}
            height={full ? 4000 : sizes.desktop.height}
            data-full={full}
            style={{
              aspectRatio: `${aspectRatio.desktop} / 1`,
              objectPosition: desktopMedia.focus
            }}
          />
        </div>
      )}
      {hasMobileImage && (
        <Image
          src={mobileMedia.file}
          alt={mobileMedia.alt}
          data-no-mobile={!hasMobileImage}
          width={full ? 4000 : sizes.mobile.width}
          height={full ? 4000 : sizes.mobile.height}
          style={{
            aspectRatio: `${aspectRatio.mobile} / 1`,
            objectPosition: mobileMedia.focus
          }}
          className={styles.mobile}
        />
      )}
    </>
  );
};

const Media = ({ blok }: { blok: MediaStoryblok }) => {
  const { desktopMedia, mobileMedia, sizes, aspectRatio } = getMediaSizes(
    blok.media[0]
  );
  const full = blok.alignment === "full";
  if (mobileMedia?.isVideo || desktopMedia?.isVideo) return <></>;
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(
        styles.base,
        blok.alignment === "full" && styles.full,
        blok.alignment === "center" && styles.center,
        blok.alignment === "left" && styles.left,
        blok.alignment === "right" && styles.right,
        blok.inset && styles.inset,
        "media-blok"
      )}
      data-blok
      data-blok-background
      data-full={full}
      data-alignment={blok.alignment}
      animated="fadeY"
    >
      <FixedMediaElement
        desktopMedia={desktopMedia}
        mobileMedia={mobileMedia}
        sizes={sizes}
        full={full}
        aspectRatio={aspectRatio}
      />
    </BlokWrap>
  );
};

export default Media;
