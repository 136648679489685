import { Slot } from "@radix-ui/react-slot";
import type { PropsWithChildren } from "react";

import styles from "./inset.module.css";

/**
 * @description
 * A component that provides an inset layout by using the Slot component from @radix-ui/react-slot.
 * Use this component to wrap content that should be inset from the edges of the screen / container.
 * Slot makes it append to the child that is passed to it.
 */

const Inset = ({
  active,
  ...props
}: PropsWithChildren<{ active?: boolean }>) => {
  return <Slot className={!active ? "" : styles.inset} {...props} />;
};

export default Inset;
