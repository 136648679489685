import type { ProductListingStoryblok } from "@/types/storyblok-blok-types";

import ProductListingUI from "./ProductListingUI";
import fetchProductListing from "./fetchProductListing";

const ProductListing = ({
  blok,
  sidebyside,
  data
}: {
  blok: ProductListingStoryblok;
  sidebyside?: boolean;
  data?: Awaited<ReturnType<typeof fetchProductListing>>;
}) => {
  return (
    <ProductListingUI blok={blok} productCards={data} sidebyside={sidebyside} />
  );
};

export default ProductListing;
