import * as NavMenu from "@radix-ui/react-navigation-menu";
import Link from "next/link";

import getLink from "@/lib/storyblok/utils/getLink";
import type { LinkStoryblok } from "@/types/storyblok-blok-types";

import clsx from "clsx";
import styles from "./header-bloks.module.css";

const MenuLink = (link: LinkStoryblok) => {
  const href = getLink(link.link);
  return href ? (
    <NavMenu.Item>
      <Link href={href} className={clsx(styles.menuButton, styles.menuLink)}>
        {link.text}
        <div className={styles.underline} />
      </Link>
    </NavMenu.Item>
  ) : (
    <></>
  );
};

export default MenuLink;
