import { AccordionGroup as UIAccordionGroup } from "@/components/atoms/Accordion";
import BlokWrap from "@/components/atoms/BlokWrap";
import type { AccordionStoryblok } from "@/types/storyblok-blok-types";
import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import AccordionItem from "./AccordionItem";
import styles from "./accordion.module.css";

const Accordion = ({ blok }: { blok: AccordionStoryblok }) => {
  return (
    <BlokWrap
      {...storyblokEditable(blok as SbBlokData)}
      animated="fadeY"
      data-layout={"page"}
      className={`hero-container`}
    >
      <UIAccordionGroup className={styles["accordion-group"]}>
        {blok.items?.map((item) => (
          <AccordionItem item={item} key={item._uid} />
        ))}
      </UIAccordionGroup>
    </BlokWrap>
  );
};

export default Accordion;
