import clsx from "clsx";
import Link from "next/link";

import Preamble from "@/components/atoms/Preamble/Preamble";
import AspectRatio from "@/components/modifiers/AspectRatioProvider";
import type { MediaElementStoryblok } from "@/types/storyblok-blok-types";

import MediaElement from "../MediaElement";
import styles from "./article-card.module.css";

export type ArticleCardType = {
  name: string;
  image: MediaElementStoryblok;
  slug: string;
  preamble: string;
};
const ArticleCard = ({ name, image, slug, preamble }: ArticleCardType) => {
  return (
    <Link href={`/${slug}`} className={styles.wrapper}>
      <AspectRatio height={282} width={414}>
        <MediaElement blok={image} />
      </AspectRatio>
      <div className={styles.title}>
        <div className={styles.titletag}>
          <Preamble>{preamble}</Preamble>
          <p className={clsx(styles["read-more"], "underline")}>DISCOVER</p>
        </div>
        <h4 className={styles.name}>{name}</h4>
      </div>
    </Link>
  );
};

export default ArticleCard;
