import type { TaggedImageType } from "../hooks/useTaggedImage";
import type { InfoPosition } from "../hooks/useTagPosition";
import UI from "./UI";

const Tag = ({
  tag,
  infoPosition,
  index,
}: {
  tag?: TaggedImageType;
  index: number;
  infoPosition: InfoPosition;
}) => {
  return <UI tag={tag} infoPosition={infoPosition} index={index} />;
};

export default Tag;
