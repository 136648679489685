import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

import { Heading, Subtitle } from "@/components/atoms";

import isEmptyString from "@/lib/utils/isEmptyString";
import isEmptyRichText from "@/lib/utils/isRichTextEmpty";
import { HeroStoryblokCustom } from "@/types/globals";
import RichTitle from "../RichTitle/RichTitle";
import StoryblokLink from "../StoryblokLink";
import styles from "./hero.module.css";
import ImageBlok from "./parts/ImageBlok";

/**
 *
 * Hero UI
 * @description The UI for the hero element - which is used inside the hero section
 * @param {number} length how many 'heros' there are in the hero section
 *
 */

export const HeroUI = ({
  blok,
  length,
  preview
}: {
  blok: HeroStoryblokCustom;
  length: number;
  preview?: boolean;
}) => {
  const {
    media,
    preamble,
    title,
    button,
    darkText,
    inset,
    backgroundOpacity,
    buttonAsLink,
    leftCtaText,
    rightCtaText,
    secondaryHeading
  } = blok;

  const fontColor = darkText
    ? { color: "black" }
    : { color: "var(--color-base)" };

  const Preamble = () =>
    isEmptyString(preamble) ? null : (
      <Subtitle style={fontColor}>{preamble}</Subtitle>
    );

  const Title = () =>
    !isEmptyRichText(title) ? null : (
      <div className="hero-title">
        <Heading
          as={"h2"}
          styledAs={length === 2 ? "h2" : "h1"}
          className={styles.heading}
          style={fontColor}
        >
          <RichTitle>{title}</RichTitle>
        </Heading>
      </div>
    );

  const SecondaryTitle = () =>
    !isEmptyRichText(secondaryHeading) ? null : (
      <div className={styles.secondary__title}>
        <Heading
          as={"h2"}
          styledAs={length === 2 ? "h2" : "h1"}
          className={styles.heading}
          style={fontColor}
        >
          <RichTitle>{secondaryHeading}</RichTitle>
        </Heading>
      </div>
    );

  const Button = () =>
    !button?.[0] ? null : (
      <StoryblokLink
        blok={{ link: button?.[0].link, text: button?.[0].text }}
        darkText={darkText}
        asLink={buttonAsLink}
      />
    );

  const LeftCTAText = () =>
    !isEmptyRichText(leftCtaText) ? null : (
      <RichTitle className={styles.cta__text}>{leftCtaText}</RichTitle>
    );
  const RightCTAText = () =>
    !isEmptyRichText(rightCtaText) ? null : (
      <RichTitle className={clsx(styles.cta__text, styles.right)}>
        {rightCtaText}
      </RichTitle>
    );

  return (
    <div
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(styles.outerwrapper, inset && styles.inset, "hero")}
    >
      <ImageBlok
        media={media}
        backgroundOpacity={backgroundOpacity}
        preview={preview}
      />
      <div className={styles.content} style={fontColor}>
        <div className={styles.content__main}>
          <Preamble />
          <Title />
          <div className={styles.button__wrapper}>
            <LeftCTAText />
            <Button />
            <RightCTAText />
          </div>
        </div>
        <SecondaryTitle />
      </div>
    </div>
  );
};
