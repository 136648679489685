import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

import type { StoreStoryblok } from "@/types/storyblok-blok-types";

import StoryblokComponent from "../../storyblok/storyblokSetup/StoryblokComponent/StoryblokComponent";

const Page = ({
  blok,
  preview
}: {
  blok: StoreStoryblok;
  preview: boolean;
}) => {
  if (!blok.body) return <p> Missing body in blok: Page</p>;

  return (
    <div
      className={clsx("page-blok")}
      {...storyblokEditable(blok as unknown as SbBlokData)}
    >
      {blok?.body?.map((blok) => {
        return (
          <StoryblokComponent blok={blok} key={blok._uid} preview={preview} />
        );
      })}
    </div>
  );
};

export default Page;
