/**
 *
 * Check if a richtext element is empty
 * @param content an array of strings or richtext elements
 * @returns {boolean} true if empty
 */

import { RichtextStoryblok } from "@/types/storyblok-blok-types";
import isEmptyString from "./isEmptyString";

const isEmptyRichText = (value: RichtextStoryblok | string) => {
  /**
   * @decsription Recursively checks if the richtext element contains any text strings
   */
  const traverse = (items: RichtextStoryblok[]): boolean => {
    return items
      .map((i) => {
        if (typeof i === "string") {
          return !isEmptyString(i);
        }
        if (i?.content) {
          return traverse(i?.content);
        }
        if (i?.text && !isEmptyString(i?.text)) {
          return true;
        }
        return false;
      })
      .flat()
      .some((i) => i);
  };

  if (typeof value === "string") return !isEmptyString(value);
  if (value?.content) return traverse(value?.content);
  if (value?.text) return !isEmptyString(value?.text);
  return false;
};

export default isEmptyRichText;