const imageSuffixes = [
  ".gif",
  ".jpg",
  ".jpeg",
  ".png",
  ".webp",
  ".svg",
  ".jpeg",
];
const videoSuffixes = [
  ".mpg",
  ".mp2",
  ".mpeg",
  ".mpe",
  ".mpv",
  ".mp4",
  ".webm",
  ".mov",
  ".avi",
];

const isImageOrVideo = (
  pathName: string
): {
  isImage: boolean;
  isVideo: boolean;
} => {
  const isVimeoVideo = pathName
    ?.toLowerCase()
    ?.startsWith("https://vimeo.com/");
  const isVideo =
    videoSuffixes?.some(
      (suffix) => pathName?.toLowerCase()?.includes(suffix)
    ) || isVimeoVideo;
  const isImage = imageSuffixes?.some(
    (suffix) => pathName?.toLowerCase()?.includes(suffix)
  );
  return {
    isVideo,
    isImage,
  };
};

export default isImageOrVideo;
