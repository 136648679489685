import clsx from "clsx";
import Image from "next/image";

import type { StoryBlokMedia } from "@/types/storyblok";

import styles from "./sb-image.module.css";

interface StoryBlokImageProps {
  media: StoryBlokMedia;
  vwMobile?: number;
  vwDesktop?: number;
  className?: string;
}

const StoryblokImage = ({
  media,
  className,
  vwMobile = 100,
  vwDesktop = 50,
}: StoryBlokImageProps) => (
  <Image
    src={media.file}
    alt={media.alt}
    fill
    className={clsx(styles.image, className)}
    sizes={`(max-width: 1024px) ${vwMobile}, (max-width: 2560px) ${vwDesktop}`}
    style={{ objectPosition: media.focus }}
  />
);

export default StoryblokImage;
